import React from 'react';

import TableRow from './TableRow';

const TableContent = (
    {
        columnsList,
        formData,
        formErrors,
        setFormData,
        onFormValueChange,
        gridWidths,
    }
) => {

    return (
        <tbody>
        {formData.length > 0 &&
            formData.map((row, i) => {
                return (
                    <TableRow
                        key={row.id}
                        row={row}
                        formErrors={formErrors?.[i]?.['children'] ?? {}}
                        setFormData={setFormData}
                        onFormValueChange={onFormValueChange}
                        columnsList={columnsList}
                        gridWidths={gridWidths}
                    />
                );
            })}
        </tbody>
    );
};

export default TableContent;
