import React from 'react';

const TableHeaders = ({columnsList, activeIndex, mouseDown, gridWidths}) => {
    let currentGridWidth = -2;

    return (
        <thead>
        <tr>
            {/* table headers */}
            {columnsList.map(
                (
                    {
                        ref,
                        key,
                        label,
                        help,
                        minWidth,
                        required,
                        sticky,
                    },
                    i
                ) => {

                    const style = {
                        minWidth: minWidth,
                    };

                    if (sticky) {
                        style['left'] = currentGridWidth + 'px';
                        currentGridWidth = parseInt(gridWidths[i]) + currentGridWidth;
                    }

                    return (
                        <th
                            ref={ref}
                            key={key}
                            id={`form_column_${key}`}
                            style={style}
                            className={`${
                                sticky ? 'isSticky' : ''
                            }`}
                        >
                            <div
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                className={`field`}
                            >
                                <div
                                    className={`f-label ${required ? 'required' : ''} `}
                                    style={{wordBreak: 'break-word'}}
                                >
                                    {label}{help != '' && help && <><br/><small
                                    dangerouslySetInnerHTML={{__html: help}}></small></>}
                                </div>
                            </div>
                            <div
                                style={{height: '100%'}}
                                onMouseDown={() => mouseDown(i)}
                                className={`resize-handle ${
                                    activeIndex === i ? 'active' : 'idle'
                                }`}
                            />
                        </th>
                    );
                }
            )}
        </tr>
        </thead>
    );
};

export default TableHeaders;
