import React, {useEffect, useState} from 'react';
import {findChoiceForValue, inputValueParser,} from '../functions/functions';
import ReactSelect, {components} from 'react-select';
import {selectStyles} from '../static/selectStyles';
import {InputNumber} from 'rsuite';

const FormField = ({row, column, onFormValueChange}) => {

    let selectStylesOverwritten = Object.assign({}, selectStyles, {
        control: (base, {isFocused}) => ({
            ...base,
            backgroundColor: 'white',
            fontSize: '11px',
            minHeight: 'inherit',
            ':hover': {borderColor: '#c45453'},
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        }),
        menuPortal: base => ({
            ...base,
            fontSize: '11px',
            zIndex: 9999,
        }),
        menu: (base) => ({
            ...base,
            width: "max-content",
            minWidth: "100%"
        }),
        singleValue: base => ({
            ...base,
            top: '50%',
        })
    })

    const {
        key,
        type,
        subType,
        choices,
        required
    } = column;

    if (type == 'checkbox') {
        return (
            <input
                type={type}
                checked={row[key]}
                className="form-control"
                onChange={(e) => {
                    onFormValueChange(row.id, key, e.currentTarget.checked);
                }}
            />
        );
    } else if (type === 'date') {
        return (
            <input
                type="date"
                value={inputValueParser(row[key], 'date', key)}
                className="form-control"
                onChange={(e) => {
                    onFormValueChange(row.id, key, e.target.value);
                }}
            />
        );
    } else if (type == 'money') {
        return (
            <InputNumber
                align="right"
                size="sm"
                value={inputValueParser(row[key], type, key)}
                step={1}
                style={{width: '100%'}}
                lang="de"
                onChange={(value, event) => {
                    onFormValueChange(row.id, key, value);
                }}
                postfix="€"
            />
        );
    } else if (type === 'text' || type == 'number') {

        if (subType == 'percentage') {
            return (
                <InputNumber
                    align="right"
                    size="sm"
                    value={inputValueParser(row[key], subType, key)}
                    step={0.1}
                    style={{width: '100%'}}
                    lang="de"
                    onChange={(value, event) => {
                        onFormValueChange(row.id, key, value);
                    }}
                    postfix="%"
                />
            );
        }

        return (
            <input
                type={subType}
                value={inputValueParser(row[key], subType, key)}
                className="form-control"
                onChange={(e) => {
                    onFormValueChange(row.id, key, e.target.value);
                }}
            />
        );
    } else if (type == 'choice') {

        const Option = props => {
            return (
                <components.Option {...props}>
                    <div>{props.data.renderLabel ?? props.data.label}</div>
                    <div style={{fontSize: 9, marginTop: 0}}>{props.data.renderSubLabel}</div>
                </components.Option>
            );
        };

        const Group = props => (
            <div>
                <components.Group {...props} />
            </div>
        );

        const hasGroups = choices.find(e => e.group != null);
        let groupedChoices = null;

        if (hasGroups) {
            groupedChoices = {};

            for (let choice of choices) {

                let choiceGroup = choice.group ?? 'Ohne Gruppe';

                if (!groupedChoices[choiceGroup]) {
                    groupedChoices[choiceGroup] = {
                        label: choiceGroup,
                        options: [],
                    }
                }

                groupedChoices[choiceGroup]['options'].push(choice);
            }

            groupedChoices = Object.values(groupedChoices);
        }

        const [bodyElement, setBodyElement] = useState(null);

        useEffect(() => {
            setBodyElement(document.body)
        },[]);

        const choiceValue = row[key] ? findChoiceForValue(column, row[key]) : null;

        return (
            <ReactSelect
                name={key}
                value={choiceValue}
                options={groupedChoices ?? choices}
                styles={selectStylesOverwritten}
                menuPortalTarget={bodyElement}
                placeholder=""
                onChange={(e) => {
                    onFormValueChange(row.id, key, e?.value);
                }}
                components={{Option, Group}}
                isClearable={!required}
                className={'react-select'}
                closeMenuOnSelect={true}
                openMenuOnFocus={true}
            />
        );
    }

    return <div>Undefined FormField</div>;
};

export default FormField;
