import React, {useCallback, useEffect, useState} from 'react';
import {
    getDefaultRowData,
    getParticipantsData,
    makeNewFormEmptyRows,
    makeRequestData,
    postToServer,
} from '../functions/functions';
import FormTable from './FormTable';
import Skeleton from '@yisheng90/react-loading';
import WorkloadButtons from './WorkloadButtons';
import {EventAggregator} from "aurelia-event-aggregator";
import {Container} from "aurelia-framework";

const ParticipantsRenderer = ({services, order}) => {

    const ea = Container.instance.get(EventAggregator);

    const [formData, setFormData] = useState([]);
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [columnsConfig, setColumnsConfig] = useState([]);
    const [formSubmitting, setFormSubmitting] = useState(false);

    const onFormValueChange = useCallback((rowId, key, value) => {

        setFormData((formData) => {

            ea.publish('sio_register_unsaved_changes', {changesKey: 'b2b-participants'});

            const affectedRow = formData.find(row => row.id === rowId);

            return formData.map(row => {

                // Sync twinRoom value to same room
                if (
                    key === 'twinRoom' &&
                    row?.b2bGroup?.id === affectedRow?.b2bGroup?.id &&
                    row.roomIndex === affectedRow?.roomIndex
                ) {

                    return {...row, [key]: value};

                } else if (row.id === rowId) {

                    if (key === 'type' || key === 'b2bGroup') {

                        const newRow = {...row, [key]: value};
                        const twinRow = formData.find(row => row?.b2bGroup?.id === newRow?.b2bGroup?.id && row.roomIndex === newRow?.roomIndex);

                        //Prefill selection
                        const newFormData = getDefaultRowData(formData, {...row, [key]: value}, columnsConfig);

                        //Preselect twinRoom if other row has it set
                        if (twinRow?.twinRoom) {
                            newRow['twinRoom'] = true;
                        }

                        return {...newRow, ...newFormData};
                    } else {
                        return {...row, [key]: value};
                    }
                } else {
                    return row;
                }
            });
        });

    }, [columnsConfig]);

    const getData = async () => {
        setLoading(true);

        const defaultData = await getParticipantsData(services.client, order.id);

        let columnData = [
            {
                property: 'action',
                type: 'buttons',
                options: {
                    minWidth: '31px',
                    hideFooterCount: true,
                },
            },
        ].concat(defaultData.fields);
        setColumnsConfig(columnData);

        setFormData(defaultData?.participants ?? []);
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    const submitForm = async () => {
        setFormSubmitting(true);

        const data = makeRequestData(
            formData,
            order,
        );

        try {
            await postToServer(data, services.client);

            setFormErrors({});
            getData();

            ea.publish('sio_unregister_unsaved_changes', {changesKey: 'b2b-participants'});

            services.flash.success('Erfolgreich gespeichert.');
            ea.publish('sio_form_post_submit', {
                config: {modelId: 'tourism-order/b2b-offer-participant'},
            });

            ea.publish('sio_form_post_submit', {
                config: {modelId: 'order/order'},
            });

        } catch (response) {

            let data = response.data;

            if (data?.errors?.children?.rows?.children) {
                setFormErrors(data?.errors?.children?.rows?.children);
            }

            services.flash.error(data.localizedMessage ?? 'Fehler bei der Übermittlung des Formulars');
        }

        setFormSubmitting(false);
    };

    return (
        <div className="participant-list" style={{opacity: loading ? 0.5 : 1}}>
            {!loading ? (
                <>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <WorkloadButtons order={order} services={services} onPostSubmit={() => {
                            getData();
                        }}/>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginBottom: '12px',
                                gap: '1rem',
                            }}
                        >
                            <button
                                className="btn btn-default no-icon btn-sm sio-action"
                                onClick={async () => {

                                    ea.publish('sio_register_unsaved_changes', {changesKey: 'b2b-participants'});

                                    setFormData(
                                        makeNewFormEmptyRows(
                                            columnsConfig
                                        )
                                    );
                                    setFormErrors({});
                                }}
                            >
                                Formular zurücksetzen
                            </button>
                            <button
                                className="btn btn-primary form-submit  btn-sm sio-action"
                                onClick={submitForm}
                            >
                                {formSubmitting ? (
                                    <div style={{display: 'flex', gap: '12px'}}>
                                        <div
                                            role="progressbar"
                                            className="rs-loader-wrapper rs-loader-speed-normal"
                                        >
                                            <div className="rs-loader">
                                                <span className="rs-loader-spin"></span>
                                            </div>
                                        </div>
                                        <div>Speichern</div>
                                    </div>
                                ) : (
                                    'Speichern'
                                )}
                            </button>
                        </div>
                    </div>
                    <FormTable
                        columns={columnsConfig}
                        setFormData={setFormData}
                        services={services}
                        formData={formData}
                        formErrors={formErrors}
                        onFormValueChange={onFormValueChange}
                    />
                </>
            ) : (
                <div style={{background: 'white', padding: '2rem'}}>
                    <Skeleton width={'100%'} height={'3rem'} rows={8}/>
                    <Skeleton width={'80%'} height={'3rem'} rows={6}/>
                    <Skeleton width={'55%'} height={'3rem'} rows={2}/>
                </div>
            )}
        </div>
    );
};

export default ParticipantsRenderer;
