import React from 'react';
import {removeOneRow, shouldFormShowField} from '../functions/functions';
import FormField from './FormField';
import {Tooltip, Whisper} from "rsuite";

const TableRow = ({row, columnsList, formErrors, setFormData, onFormValueChange, gridWidths}) => {

    let currentGridWidth = -2;

    return (
        <tr>
            {columnsList?.length &&
                columnsList.map((column, index) => {
                    const {key, label, sticky} = column;

                    const style = {};

                    if (sticky) {
                        style['left'] = currentGridWidth + 'px';
                        currentGridWidth = parseInt(gridWidths[index]) + currentGridWidth;
                    }

                    if (key == 'action') {
                        return (
                            <td key={key} style={style} className={`${sticky ? 'isSticky' : ''}`}>
                                <div
                                    type="button"
                                    className="btn btn-sm"
                                    style={{color: '#c45453'}}
                                    onClick={() => {
                                        removeOneRow(row?.id, setFormData);
                                    }}
                                    title="Löschen"
                                >
                                    <span className="fa fa-xmark"></span>
                                </div>
                            </td>
                        );
                    }

                    const errors = formErrors?.[key]?.['errors'] ?? [];

                    const showFormField = shouldFormShowField(key, row, column);

                    return (
                        <td
                            key={index}
                            style={style}
                            className={`${sticky ? 'isSticky' : ''} ${errors && errors.length > 0 ? ' hasErrors' : ''} ${ !showFormField ? 'hiddenField' : '' }`}
                        >
                            {errors && errors.length > 0 ?
                                <Whisper
                                    trigger="hover"
                                    placement="auto"
                                    speaker={
                                        <Tooltip>
                                            {errors.join(', ')}
                                        </Tooltip>
                                    }>
                                    <div>
                                        {showFormField ? (
                                            <FormField
                                                row={row}
                                                column={column}
                                                onFormValueChange={onFormValueChange}
                                            />
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </Whisper> :
                                <>
                                    {showFormField ? (
                                        <FormField
                                            row={row}
                                            column={column}
                                            onFormValueChange={onFormValueChange}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </>
                            }
                        </td>
                    );
                })}
        </tr>
    );
};

export default React.memo(TableRow);
