import React from 'react';
import {ActionButton} from '../../../action/react/action-button';
import * as _ from "lodash";

const WorkloadButtons = ({order, onPostSubmit}) => {
    return (
        <div className="btn-group">
            <ActionButton
                config={{
                    type: 'workflow',
                    label: 'Excel Vorlage herunterladen',
                }}
                context={{
                    workflowId: 'tourism-order/download-excel',
                    successMessage: 'Teilnehmer erfolgreich erfasst',
                    label: 'tourism-order.b2b.generate-template',
                    id: order.id,
                }}
                size={'xs'}
                onSubmitted={(result)=> {
                    const url = _.get(result, '0.url');

                    if (url) {
                        if (Array.isArray(url)) {
                            url.forEach((urlPart) => {
                                window.open(urlPart);
                            });
                        } else {
                            window.location.href = url;
                        }
                    }

                }}
            />

            <ActionButton
                config={{
                    type: 'workflow',
                    label: 'Gefüllte Liste hochladen',
                }}
                context={{
                    workflowId: 'tourism-order/upload-excel',
                    formId: 'tourism-order/upload-excel',
                    successMessage: 'Teilnehmer erfolgreich erfasst',
                    label: 'tourism-order.b2b.upload-excel',
                    id: order.id,
                }}
                size={'xs'}
                onSubmitted={(result)=> {
                    console.log('RESULT', result);

                    onPostSubmit(result);
                }}
            />
        </div>
    );
};

export default WorkloadButtons;
