export class RouterHistory {

    constructor() {
        window.addEventListener(
            'storage',
            this._handleStorageChange.bind(this)
        );
    }

    _handleStorageChange(event) {
        if (event.key === 'sio-router-history') {
            window.dispatchEvent(new Event("sio-router-history"))
        }
    }

    static get history() {
        return JSON.parse(localStorage.getItem('sio-router-history') || '[]');
    }

    static set history(history) {
        return localStorage.setItem('sio-router-history', JSON.stringify(history));
    }

    static add(item) {
        const history = RouterHistory.history;

        if (0 < history.length && item.href === history[0].href) {
            return;
        }

        if (!item.title.length) {
            return;
        }

        history.unshift(item);

        while (history.length > 10) {
            history.pop();
        }

        RouterHistory.history = history;
        window.dispatchEvent(new Event("sio-router-history"))
    }

    // needs to be un-static due to Aurelia's API
    // noinspection JSMethodCanBeStatic
    run(instruction, next) {

        if (!instruction.config.navModel || !instruction.config.navModel.title) {
            return next();
        }

        let object = {
            title: instruction.config.navModel.title.replace(/(<([^>]+)>)/ig, ''),
            href: instruction.fragment + '?' + instruction.queryString,
        };

        RouterHistory.add(object);
        return next();
    }
}
