import {v4 as uuidv4} from 'uuid';
import {I18N} from "aurelia-i18n";
import {Container} from "aurelia-framework";
import {ConditionMatcher} from "../../../condition-builder/condition-matcher";

const i18n = Container.instance.get(I18N)

export const getParticipantsData = async (client, id) => {
    return await client.get('tourism-order/b2b-participants/list/' + id);
};

export const removeOneRow = (id, setFormData) => {
    setFormData((formData) => {
        return formData.filter((row) => row?.id !== id);
    });
};

const partdateVal = (value) => {
    if (value) {
        value = value.toString().slice(0, 10);
        return value;
    }
};
export const inputValueParser = (value, subType, key, nestedSubtype = '') => {
    if (subType == 'nested') {
        if (value) {
            const fieldVal = value[key];
            if (nestedSubtype == 'date') {
                return !fieldVal ? '' : partdateVal(fieldVal);
            }
            return fieldVal;
        }
    }

    if (subType == 'date') {
        const val = partdateVal(value);
        return val;
    }

    if (typeof value == 'object') {
        const val = '';
        return val;
    }

    return value;
};

export const postToServer = async (data, client) => {
    return await client.request(
        'post',
        'tourism-order/update-b2b-participant-list/trigger',
        data,
    );
};

export const shouldFormShowField = (key, data, column) => {
    const showIf = column?.showIf;
    const matcher = Container.instance.get(ConditionMatcher);

    if (showIf != null && Object.keys(showIf)?.length > 0) {
        return matcher.matchConditions(data, showIf);
    } else {
        return true;
    }
};

export const getDefaultRowData = (formData, item, columnsConfig) => {

    const isDriver = item.type === 'driver';

    if (!item.b2bGroup) {
        return {};
    }

    const b2bGroupColumn = columnsConfig.find(e => (e.property === 'b2bGroup'));
    const b2bGroupChoice = findChoiceForValue(b2bGroupColumn, item.b2bGroup);
    const b2bGroupFormData = b2bGroupChoice?.formData;

    if (!b2bGroupChoice || !b2bGroupFormData) {
        return {};
    }

    const returnData = {};

    for (let column of columnsConfig) {
        if (column.property.startsWith('order_item_')) {
            returnData[column.property] = column.type === 'choice' ? undefined : false;
        }
    }

    for (let formKey in b2bGroupFormData) {

        const column = columnsConfig.find(e => (e.property === formKey));

        if (!column || (!column?.options?.driverIsParticipant && isDriver)) {
            continue;
        }

        for (let value of b2bGroupFormData[formKey]) {

            if (value === true) {
                returnData[formKey] = value;
                break;
            }

            const choice = findChoiceForValue(column, value);

            if (!choice.max) {
                returnData[formKey] = value;
                break;
            }

            const count = countUsages(formData, formKey, choice.value);

            //Do not fill higher than the b2bGroupFormData as well
            if (count < Math.min(choice.max, b2bGroupChoice.maxPerRoom)) {
                returnData[formKey] = value;
                break;
            }
        }
    }

    return returnData;
};

export const createHeaders = (columns) => {
    return columns
        .map(
            (column) => {
                return {
                    key: column.property,
                    label: i18n.tr(column.label ?? column.checkboxLabel),
                    help: column.help,
                    width: column?.options?.width,
                    minWidth: column?.options?.minWidth ?? '136px',
                    required: column.required ?? false,
                    sticky: column?.options?.sticky ?? false,
                    defaultValue: column.default || undefined,
                    hideFooterCount: column.options?.hideFooterCount ?? false,
                    driverIsParticipant: column.options?.driverIsParticipant ?? false,
                    choices: column.choices ?? [],
                    type: column.type,
                    subType: column.subType,
                    showIf: column.showIf,
                    onPostChange: column.onPostChange,
                };
            }
        );
};

export const makeRequestData = (
    formValues,
    order,
) => {

    let postableData = formValues.map((participant) => {

        let newParticipant = {
            ...participant,
        };

        if (newParticipant?.cancellationPercentage) {
            newParticipant.cancellationPercentage = Number(
                newParticipant.cancellationPercentage || 0
            );
        }

        if (participant?.cancellationAmount) {
            newParticipant.cancellationAmount = {
                amount: Number(participant?.cancellationAmount) * 100,
                currency: 'EUR',
            };
        }

        delete newParticipant?.id;
        delete newParticipant?.action;

        return newParticipant;
    });

    return {
        order: {id: order.id, modelId: order.modelId},
        rows: postableData,
    };
};

export const addNewRow = (formData, b2bGroup, columnsConfig) => {
    const count = countUsages(formData, 'b2bGroup', b2bGroup.value);
    let roomNumber = Math.ceil((count + 1) / b2bGroup.maxPerRoom);
    let newRoomIndex = (count + 1) % b2bGroup.maxPerRoom;

    let row = {
        id: uuidv4(),
        type: 'participant',
        b2bGroup: b2bGroup.value,
        roomIndex: roomNumber,
    };

    for (let column of columnsConfig) {

        if (!column.default) {
            continue;
        }

        if (column.property === 'salutation') {
            row['salutation'] = newRoomIndex === 0 && b2bGroup.maxPerRoom > 1 ? 'female' : 'male';
            continue;
        }

        row[column.property] = column.default;
    }

    return {...row, ...getDefaultRowData(formData, row, columnsConfig)};
}

export const makeNewFormEmptyRows = (columnsConfig) => {
    const b2bGroupColumn = columnsConfig.find(e => (e.property === 'b2bGroup'));
    let participants = [];

    b2bGroupColumn.choices.forEach((config) => {

        for (let i = 1; i <= config.participantCount; i++) {
            participants.push(addNewRow(participants, config, columnsConfig));
        }
    });

    return participants;
};

export const countUsages = (data, key, value) => {
    let count = 0;

    for (let rowId in data) {

        if (data[rowId]?.[key] === value || (data[rowId]?.[key]?.id && data[rowId]?.[key].id === value?.id)) {
            count++;
        }
    }

    return count;
}

export const findChoiceForValue = (
    column,
    value,
) => {
    for (let col of column.choices ?? []) {
        if (col.value === value || (col.value?.id && col.value?.id === value?.id)) {
            return col;
        }
    }

    return null;
};

export const summaryCounter = (
    formData,
    key,
    column,
) => {

    if (column.type === 'choice') {

        let countByValues = {};

        for (let rowId in formData ?? {}) {

            let row = formData[rowId];

            if (!shouldFormShowField(key, row, column)) {
                continue;
            }

            let value = row?.[key];

            if (!value) {
                continue;
            }

            let label = findChoiceForValue(column, value)?.label;

            if (!label) {
                continue;
            }

            if (!countByValues[label]) {
                countByValues[label] = 0;
            }

            countByValues[label]++;
        }

        let label = [];

        for (let labelId in countByValues) {
            label.push([countByValues[labelId] + 'x ' + labelId, countByValues[labelId], labelId]);
        }

        label.sort(function (a, b) {
            return ('' + a[2]).localeCompare(b[2]);
        });

        label = label.map(function (e) {
            return e[0];
        });

        return label.join('<br>');
    }

    return formData?.reduce((total, current) => {
        return current[key] ? total + 1 : total;
    }, 0);
};
