import React from 'react';
import {summaryCounter} from '../../functions/functions';

const SummaryRow = ({columnsList, formData, gridWidths}) => {

    let currentGridWidth = -2;

    return (
        <tr className="summary">
            {columnsList.map(
                (col, i) => {

                    const {
                        key,
                        sticky,
                        hideFooterCount,
                    } = col;

                    const style = {
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'sticky',
                        bottom: '0',
                        background: 'white',
                        fontSize: '12px',
                        zIndex: 100,
                    };

                    if (sticky) {
                        style['left'] = currentGridWidth + 'px';
                        currentGridWidth = parseInt(gridWidths[i]) + currentGridWidth;
                    }

                    return (
                        <td
                            key={key + i}
                            style={style}
                            className={`${
                                sticky ? 'isSticky footerSticky' : ''
                            } `}
                        >
                            {!hideFooterCount ? <span dangerouslySetInnerHTML={{__html: summaryCounter(
                                    formData,
                                    key,
                                    col,
                                )}}></span> : null }
                        </td>
                    );
                }
            )}
        </tr>
    );
};

export default SummaryRow;
