import { useEffect, useState } from 'react';

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);

      return item
        ? !isJsonString(item)
          ? item
          : JSON.parse(item)
        : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      localStorage.setItem(key, JSON.stringify(valueToStore));
      window.dispatchEvent(new Event(key));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      try {
        const item = localStorage.getItem(key);
        setStoredValue(
          item ? (!isJsonString(item) ? item : JSON.parse(item)) : initialValue
        );
      } catch (error) {
        console.error(error);
      }
    };
    const existingListeners = window.getEventListeners?.(window)?.[key] || [];
    if (existingListeners.length === 0) {
      window.addEventListener(key, handleStorageChange);
    }
    return () => {
      window.removeEventListener(key, handleStorageChange);
    };
  }, []);

  return [storedValue, setValue];
};

export default useLocalStorage;
